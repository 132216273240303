export const InitialState = {
  headerBg: false,
  hamburgerOpen: false,
  activeId: null,
  linkClicked: null,
};

export const Reducer = (state, action) => {
  switch (action.type) {
    case "SET_HEADER_BG":
      return {
        ...state,
        headerBg: action.payload,
      };
    case "SET_MOBILE_MENU":
      return {
        ...state,
        hamburgerOpen: action.payload,
      };
    case "SET_ACTIVE_ID":
      return {
        ...state,
        activeId: action.payload,
      };
    case "SET_LINK_CLICKED":
      return {
        ...state,
        linkClicked: action.payload,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
